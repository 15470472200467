import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Section, { BackgroundColor, TextAlign } from "components/section";
import Step from "components/step";
import Separator from "components/separator";
import ImageBlock from "components/imageBlock";
import CTA from "components/cta";
import image1 from "images/centre-de-tri-2-etcheverry-mindurry.jpg";
import step1 from "images/assessment.png";
import step2 from "images/step2.png";
import step3 from "images/step21.png";
import step4 from "images/step22.png";
import step5 from "images/step3.png";
export const _frontmatter = {
  "meta": {
    "title": "Etcheverry Mindurry | La solution pour la collecte, le tri et la valorisation de vos déchets",
    "description": null
  }
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Section background={BackgroundColor.DARK} mdxType="Section">
      <h1>{`La solution sur mesure pour la collecte et le traitement de vos déchets`}</h1>
      <h3>{`Le traitement de vos déchets non dangereux`}</h3>
      <p>{`Nous assurons le traitement de l’ensemble des Déchets Industriels Non Dangereux
(DIND) appelés aussi Déchets Industriels Banaux (DIB) produits par les
entreprises, les industriels, les collectivités ou bien encore les artisans. `}</p>
      <p>{`Sont concernés les produits`}{` `}{`comme`}{` `}{`:`}</p>
      <ul>
        <li parentName="ul">{`Le bois`}</li>
        <li parentName="ul">{`Les déchets verts`}</li>
        <li parentName="ul">{`Le carton`}</li>
        <li parentName="ul">{`Les gravats`}</li>
        <li parentName="ul">{`La ferraille et les métaux non ferreux`}</li>
        <li parentName="ul">{`Le verre`}</li>
        <li parentName="ul">{`Le plastique`}</li>
      </ul>
      <h3>{`Notre centre de tri des déchets`}</h3>
      <p>{`Installation Classée pour la Protection de l’Environnement (ICPE) depuis fin 2016,
notre centre de tri couvert d’une surface de 700 m`}<sup>{`2`}</sup>{` nous permet d’avoir une démarche
approfondie en termes de revalorisation des déchets.`}</p>
      <p>{`Les opérations de tri des déchets sont opérées sur une dalle béton sécurisée prévue
à cet effet.`}</p>
      <p>{`Un contrôle visuel est d’abord effectué pour vérifier la qualité du flux. Un tri
au sol est ensuite réalisé à l’aide d’une pelle à grapin et de deux manutentionnaires.
Ce mode de fonctionnement méticuleux permet d’optimiser notre taux de valorisation.
Les fractions recyclables des déchets sont conditionnées puis transportées vers les centres de valorisation de matières premières. Le refus de tri est acheminé vers des sites agréés.`}</p>
    </Section>
    <ImageBlock content={image1} width={100} mdxType="ImageBlock" />
    <Section textAlign={TextAlign.CENTER} width={60} mdxType="Section">
      <h2>{`Le fonctionnement de notre centre de tri des déchets`}</h2>
      <Separator mdxType="Separator" />
      <Step.Red>1</Step.Red>
      <p><strong parentName="p">{`ÉTUDE DE VOS`}{` `}{`BESOINS ET`}{` `}{`DEVIS`}</strong></p>
      <ImageBlock content={step1} width={25} mdxType="ImageBlock" />
      <p>{`Volume et type de déchets, emplacement du chantier, durée`}</p>
      <Separator mdxType="Separator" />
      <Step.Red>2</Step.Red>
      <p><strong parentName="p">{`PLANIFICATION DE`}{` `}{`L'OPÉRATION`}</strong></p>
      <ImageBlock content={step2} width={25} mdxType="ImageBlock" />
      <p>{`Pose, rotation ou enlèvement sous 24h`}</p>
      <Separator mdxType="Separator" />
      <Step.Red>3</Step.Red>
      <p><strong parentName="p">{`ACHEMINEMENT DES`}{` `}{`DÉCHETS VERS`}{` `}{`NOTRE`}{` `}{`CENTRE`}</strong></p>
      <ImageBlock content={step3} width={25} mdxType="ImageBlock" />
      <p>{`Pesée des déchets à l'entrée du centre de tri`}</p>
      <Separator mdxType="Separator" />
      <Step.Red>4</Step.Red>
      <p><strong parentName="p">{`PHASE DE TRI SUR NOTRE`}{` `}{`PLATEFORME`}</strong></p>
      <ImageBlock content={step4} width={25} mdxType="ImageBlock" />
      <p>{`Déchargement et triage des déchets`}</p>
      <Separator mdxType="Separator" />
      <Step.Red>5</Step.Red>
      <p><strong parentName="p">{`ÉVACUATION DES DÉCHETS`}{` `}{`VALORISÉS`}</strong></p>
      <ImageBlock content={step5} width={25} mdxType="ImageBlock" />
      <p>{`Transport des déchets valorisés vers les centres de traitement agréés`}</p>
    </Section>
    <Section background={BackgroundColor.DARK} mdxType="Section">
      <h2>{`Vous possédez déjà votre propre moyen de collecte ?`}</h2>
      <p>{`En tant que professionnel, vous pouvez utiliser notre centre de tri pour y
décharger l’ensemble de vos déchets non dangereux.`}</p>
      <p>{`Il conviendra de nous consulter en amont pour connaître le protocole interne à
respecter ainsi que la tarification appliquée.`}</p>
      <p>{`Dans le cas contraire, faites appel à notre service de location de bennes. `}</p>
    </Section>
    <Section id="location-bennes" mdxType="Section">
      <h2>{`La location de bennes`}</h2>
      <p>{`Que cela soit pour une période déterminée ou pour un besoin sur le long terme,
nous mettons à votre disposition le contenant adapté à votre type de déchets
et à la nature de votre chantier.`}</p>
      <p><a href="https://entreprendre.service-public.fr/vosdroits/R17000" target="_blank">Une demande d’autorisation</a> est
à prévoir auprès de la commune concernée dans le cas où la benne est
déposée sur la voirie ou qu’elle empiète dessus. Cette procédure doit être 
effectuée au moins 8 jours avant le dépôt du contenant.</p>
      <p>{`Un devis vous est adressé dans un délai de 24 heures et comprend : la pose de
la benne, la rotation, l’enlèvement du contenant ainsi que le traitement
des déchets.`}</p>
    </Section>
    <Section background={BackgroundColor.RED} mdxType="Section">
  <CTA label="Nous contacter" to="/contact" mdxType="CTA">
    Vous souhaitez en savoir plus sur nos services professionnels&nbsp;?
  </CTA>
    </Section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      